import podCatTemplate from "./podcast-cat-listing.handlebars";
import podcastsTemplate from "./podcasts-listing.handlebars";
// import podheroTemplate from "./podhero.handlebars";
import podEpsdTemplate from "./episodes.handlebars";
import categories from './podcast-category.json';
const courseLab = require("../../assets/podcast-cover-course-lab.jpg?placeholder=true&sizes[]=100,sizes[]=279,sizes[]=258");
const makingIt = require("../../assets/podcast-cover-making-it.jpg?placeholder=true&sizes[]=100,sizes[]=279,sizes[]=258");
const justBetCoach = require("../../assets/podcast-cover-between-coaches.jpg?placeholder=true&sizes[]=100,sizes[]=279,sizes[]=258");
const onceUponBusiness = require("../../assets/podcast-cover-once-upon-business.jpg?placeholder=true&sizes[]=100,sizes[]=279,sizes[]=258");
const soulSavvyBusiness = require("../../assets/podcast-cover-soul-savvy-business.jpg?placeholder=true&sizes[]=100,sizes[]=279,sizes[]=258");
const selfAwakenedLifestyle = require("../../assets/podcast-cover-self-awakened-lifestyle.jpg?placeholder=true&sizes[]=100,sizes[]=279,sizes[]=258");
const toLeadIsHuman = require("../../assets/podcast-cover-to-lead-is-human.jpg?placeholder=true&sizes[]=100,sizes[]=279,sizes[]=258");
const blowingUp = require("../../assets/podcast-cover-blowing-up.jpg?placeholder=true&sizes[]=100,sizes[]=279,sizes[]=258");
const forBetterForWork = require("../../assets/podcast-cover-for-better-or-for-work.jpg?placeholder=true&sizes[]=100,sizes[]=279,sizes[]=258");
const behindTheLaunch = require("../../assets/podcast-cover-behind-the-launch.jpg?placeholder=true&sizes[]=100,sizes[]=279,sizes[]=258");
const consciousnessExplored = require("../../assets/podcast-cover-consciousness-explored.jpg?placeholder=true&sizes[]=100,sizes[]=279,sizes[]=258");
const teacherTom = require("../../assets/podcast-cover-teacher-tom.jpg?placeholder=true&sizes[]=100,sizes[]=279,sizes[]=258");
const neuroscienceOfCoaching = require("../../assets/podcast-cover-neuroscience-of-coaching.jpg?placeholder=true&sizes[]=100,sizes[]=279,sizes[]=258");

export class podcastService {
  constructor() {
    this.wp = '';
    this.categories = categories;
    this.images = {
      'course-lab': courseLab,
      'making-it': makingIt,
      'just-between-coaches': justBetCoach,
      'once-upon-a-business': onceUponBusiness,
      'soul-savvy-business': soulSavvyBusiness,
      'self-awakened-lifestyle': selfAwakenedLifestyle,
      'to-lead-is-human': toLeadIsHuman,
      'blowing-up': blowingUp,
      'for-better-or-for-work':forBetterForWork,
      'behind-the-launch': behindTheLaunch,
      'consciousness-explored': consciousnessExplored,
      'teacher-toms-podcast-taking-play-seriously': teacherTom,
      'neuroscience-of-coaching': neuroscienceOfCoaching
    };

    console.log(consciousnessExplored.placeholder);
  }

  setDiv(div) {
    this.div = document.getElementById(div);
  }

  setId(id) {
    this.id = id;
  }

  setCat(cat = 0) {
    this.cat = cat;
  }

  setPerPage(page = 0) {
    this.perpage = page;
  }

  observerConfig() {
    return {
      root: this.div,
      rootMargin: '10px',
      threshold: 0
    }
  }

  initPodCatObserver(observer = 'body') {
    this.observer = new IntersectionObserver(this.podcastCatObserverCallback, this.observerConfig);
    this.observer.observe(document.querySelector(observer));
  }

  podcastCatObserverCallback = (entries, observer) => {
    if(entries[0].isIntersecting === true) {
      const req = new Request(`${WPURL}wp-json/mrs/v1/podcast/categories`, { priority: "high" });
      fetch(req)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Something went wrong on API server!");
        }
      })
      .then((data) => {
        this.loadCategory(data);
        observer.disconnect();
      })
      .catch((error) => {
        console.error(error);
      });
    }
  }

  initCategories() {
    this.loadCategory(this.categories);
  }

  loadCategory(categories) {
    if(this.cat) {
      var category = Object.values(categories).filter(x =>  x.slug == this.cat);
      let podcover = this.images[category[0].slug].images[2];
      document.getElementById('podcover').innerHTML = `<img src="${podcover.path}" alt="${category[0].name}" class="img-fluid d-block mx-auto" width="258" height="${podcover.height}" fetchPriority="low" decoding="async">`;
      document.getElementById('podlisten').innerHTML = `<li class="list-item"><a href="${category[0].links.itunes}" class="link-light" target="_blank" rel="noopener" aria-label="itunes"> <i class="mrs-icon mrs-podcast-itunes fs-3 me-2"></i>Apple Podcasts</a> </li> <li class="list-item"><a href="${category[0].links.google}" class="link-light text-capitalize" target="_blank" rel="noopener" aria-label="google"> <i class="mrs-icon mrs-podcast-google fs-3 me-2"></i>YouTube playlist</a> </li> <li class="list-item"><a href="${category[0].links.spotify}" class="link-light text-capitalize" target="_blank" rel="noopener" aria-label="spotify"> <i class="mrs-icon mrs-podcast-spotify fs-3 me-2"></i>spotify Podcasts</a> </li>`;
      var podcats = Object.values(categories).filter(x =>  x.slug != this.cat);
      this.div.innerHTML = podCatTemplate({podcats: podcats, category:this.cat, 'images': this.images, column:3});
    } else {
      this.div.innerHTML = podCatTemplate({podcats: Object.values(categories), category:this.cat, 'images': this.images, column:2});
    }
  }

  loadSpotifyPlayer() {
    var category = Object.values(categories).filter(x =>  x.slug == this.cat);
    document.getElementById('podplayer').innerHTML = `<iframe title="${category[0].name}" loading="lazy" width="100%" height="166" scrolling="no" frameborder="no" src="${category[0].player_link}"></iframe>`
  }

  initPodcastsObserver(observer = 'body') {
    this.observer = new IntersectionObserver(this.podcastsObserverCallback, this.observerConfig);
    this.observer.observe(document.querySelector(observer));
  }

  podcastsObserverCallback = (entries, observer) => {
    if(entries[0].isIntersecting === true) {
      // Promises
      this.getCategoryEpisode();
      observer.disconnect();
    }
  }

  async getCategoryEpisode() {
      var perPage = this.div.getAttribute('limit');
      if(this.div.getAttribute('category')) {
        this.cat = this.div.getAttribute('category');
      }
      var requrl = `${WPURL}wp-json/mrs/v1/podcasts?category=${this.cat}`;
      if(perPage) {
        requrl+=`&per_page=${perPage}`;
      }
      const req = new Request(requrl, { priority: "high" });
      await fetch(req)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Something went wrong on API server!");
        }
      })
      .then((data) => {
        this.div.innerHTML = podcastsTemplate({podcasts: data, category: this.cat, length: this.cat && data.length > 8 ? true : false, class:'bg-light'});
      })
      .catch((error) => {
        console.error(error);
      });
  }

  initPodEpisode(observer = 'body') {
    this.observer = new IntersectionObserver(this.podcastsEpisodeObserverCallback, this.observerConfig);
    this.observer.observe(document.querySelector(observer));
  }

  podcastsEpisodeObserverCallback = (entries, observer) => {
    if(entries[0].isIntersecting === true) {
      this.getPodcastEpisode();
      observer.disconnect();
    }
  }

  async getPodcastEpisode() {
    const req = new Request(`${WPURL}wp-json/mrs/v1/podcasts`, { priority: "high" });
    await fetch(req)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Something went wrong on API server!");
        }
      })
      .then((data) => {
        this.div.innerHTML = podEpsdTemplate({episodes: data, 'images': this.images});
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getCategory(data) {
    var cat_id = data.categories[0];
    let category = data._embedded['wp:term'][0].filter(category => category.id == cat_id)[0];
    return category;
  }

  translateDataToTree(data) {
    let parents = data.filter(value => value.parent == 'undefined' || value.parent == 0)
    let childrens = data.filter(value => value.parent !== 'undefined' && value.parent != 0)
    let translator = (parents, childrens) => {
      parents.forEach((parent) => {
        childrens.forEach((current, index) => {
          if (current.parent === parent.id) {
            let temp = JSON.parse(JSON.stringify(childrens))
            temp.splice(index, 1)
            translator([current], temp)
            typeof parent.childrens !== 'undefined' ? parent.childrens.push(current) : parent.childrens = [current]
          }
        })
      })
    }
    translator(parents, childrens)
    return parents
  }

  onClick(cb) {
    this.addValuesButton.addEventListener("click", cb);
  }
}
