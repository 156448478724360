var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./podcast-cat.handlebars"),depth0,{"name":"podcast-cat","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row row-cols-1 row-cols-md-"
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"column") : depth0), depth0))
    + " g-4 justify-content-center\">\n  "
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"podcats") : depth0), depth0),{"name":"podcats","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":2,"column":43}}})) != null ? stack1 : "")
    + "\n</div>\n";
},"usePartial":true,"useData":true});