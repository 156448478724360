var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./podcast.handlebars"),depth0,{"name":"podcast","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"row mt-5 justify-content-center page-navi\">\n    <div class=\"col-12 next text-center\">\n      <a href=\"./blog/podcast/"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"category") : depth0), depth0))
    + "/page/2/\" class=\"btn btn-light px-4 mt-3 border float-none\">Next</a>\n    </div>\n  </div>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4\">\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? lookupProperty(depth0,"podcasts") : depth0), depth0),{"name":"podcasts","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":4,"column":15}}})) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"length") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":13,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});