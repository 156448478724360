//load pillar css
import './../../index';
import './../../css/podcast.scss';

import { podcastService } from './../../components/podcast/podcast.js';

var podcat = new podcastService();
podcat.setDiv("mrsPodcasstCat")
podcat.initCategories();

let options = {
  rootMargin: '20px',
  threshold: 0
};
let observer = new IntersectionObserver(podcastEpsdObserver, options);
let podcastEpsd = document.querySelector("#mrsPodEpsd");
observer.observe(podcastEpsd);

function podcastEpsdObserver(entries, observer) {
  entries.forEach((entry) => {
    if(entry.isIntersecting) {
        var podepsd = new podcastService();
        podepsd.setDiv("mrsPodEpsd")
        podepsd.getPodcastEpisode();
        observer.disconnect();
    }
  });
}