/*!
* Project code
*/
// Prevent showing animation on window resize
let resizeTimer;
window.addEventListener("resize", () => {
  document.body.classList.add("resize-animation-stopper");
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(() => {
    document.body.classList.remove("resize-animation-stopper");
  }, 400);
});

// document.addEventListener("DOMContentLoaded", function() {
// Menu toogle on mobile
const navToggle = document.querySelector('.nav-toggle');
const menuToggle = document.querySelector('.menu-toggle');
if(navToggle) {
  navToggle.addEventListener('click', function(e) {
    this.classList.toggle('open');
    menuToggle.classList.toggle('active');
    e.stopPropagation();
  });
}

// Dropdown toogle on mobile
const dropdown = document.querySelector('.dropdown a');
if(dropdown) {
  dropdown.addEventListener('click', function(e) {
    this.nextElementSibling.classList.toggle('show');
    this.parentNode.classList.toggle('active');
    e.stopPropagation();
  });
}

// Menu toogle on mobile
const searchToggle = document.querySelector('.search-toggle');
const searchBoxToggle = document.querySelector('#searchNavbar');

if(searchToggle) {
  searchToggle.addEventListener('click', function(e) {
    this.classList.toggle('open');
    searchBoxToggle.classList.toggle('active');
    e.stopPropagation();
  });
}

// });
